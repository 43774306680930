import logo from './cropped-aquatherm_Logo.gif';
import './App.css';
import 'antd/dist/antd.css';
import './index.css';
import { Select, Radio, Collapse, Button, notification, Table, DatePicker, Modal, ConfigProvider, Input, Form, Spin } from 'antd';
import deDE from 'antd/lib/locale/de_DE';
import { DownloadOutlined, PauseOutlined, CaretRightOutlined } from '@ant-design/icons';

import React from 'react';
import Tools from './Tools'
import Service from './Service';

import {Line, Chart} from 'react-chartjs-2';
import zoomPlugin from "chartjs-plugin-zoom";
import 'chartjs-adapter-moment';
import 'hammerjs';

import {CSVLink} from "react-csv";
import config from "./config.json";
import moment from 'moment';

Chart.register(zoomPlugin); // REGISTER PLUGIN

const tools = new Tools();
const myService = new Service();

const { Option } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const modeOptions = [
  { label: 'Live', value: 'live' },
  { label: 'Zeitraum', value: 'timeslot' }
];

const historyLimit = 43200;
const maxLiveValues = 500;



function LoginMsg(props) {
  if (props.error && props.visible) {
    return <div>Logindaten fehlerhaft.</div>
  } else {
    return <div></div>
  }
}


class App extends React.Component {

  constructor (props) {
    super(props);

    this.chartReference = React.createRef();

    this.state = {
      devices: [],
      selectedDevice: null,
      configurationList: [],
      selectedConfigurationId: null,
      firstConfigurationId: null,
      startTime: 0,
      endTime: 0,
      historyValues: null,
      mode: 'live',
      modal2Visible: false,
      showLoginModal: true,
      hold: false,
      holdTime: null,
      working: false,
      showChartResetButton: false
    };

    this.changeTimeslot = this.changeTimeslot.bind(this);
    this.handleDeviceChange = this.handleDeviceChange.bind(this);
    this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
    this.holdLive = this.holdLive.bind(this);
    this.onOpenCloseChangeTimepicker = this.onOpenCloseChangeTimepicker.bind(this);
    this.chartDidZoom = this.chartDidZoom.bind(this);
    this.resetZoom = this.resetZoom.bind(this);
    this.readLiveRequest = this.readLiveRequest.bind(this);

    this.timer = setInterval(this.readLiveRequest, 1000);
  }


  readLiveRequest() {

    if (this.state.mode === "live" && !this.state.hold && !this.state.showLoginModal) {
      console.log("read live Request");

      let lastHoldTime = this.state.holdTime;
      let until = new Date().valueOf();
      this.setState({working: true});
      //console.log("read live Request until: "+until+ "from: " +(until-2000) + "to: "+(until-1000));
      if (lastHoldTime) {
        console.log(`Last HoldTime: ${lastHoldTime}`)
        this.setState({holdTime: null});
      }
      myService.getHistoryValues(this.state.selectedDevice, (lastHoldTime ? (lastHoldTime-5000) : (until-5000)), (until-4000), (responseData, status) => {
        this.setState({working: false});
        if (responseData.length > 0) {
          let updateValues = [];

          if (this.state.historyValues != null) {
            updateValues = updateValues.concat(this.state.historyValues);
          }
          updateValues = updateValues.concat(responseData);
          
          updateValues.sort((a, b) => a.timestamp - b.timestamp);

          if (updateValues.length > maxLiveValues) {
            updateValues = updateValues.slice(updateValues.length-maxLiveValues);
          } 
            
          this.setState({
              historyValues: updateValues,
          });

          
          //console.log(`History Array: ${JSON.stringify(updateValues, null, 2)}\n`);
          //console.log(`Response lenght: ${responseData.length}, history length: ${updateValues.length}, Data0: ${JSON.stringify(responseData[0], null, 2)}, Data: ${JSON.stringify(responseData, null, 2)}`);
        }
      });
    }
  }


  openNotification(title, msg) {
    console.log("openNotification: " + title + ", " + msg);
    const args = {
      message: title,
      description:
        msg,
      duration: 5,
    };
    notification.warn(args);
  };


  componentDidMount() {
    console.log("App did mount");
    console.log(this.chartReference);
    /* *
    * read devices on load
    *
    * */
    myService.getDevices((deviceList, status) => {
      console.log("DeviceList: " + deviceList);
      if (deviceList != null) {
        this.setState({
          selectedDevice: deviceList[0],
          devices: deviceList
        }, () => {
            this.handleDeviceChange(this.state.selectedDevice);
          }
        );
      }
    });
  }


  /* *
  * read configurations on device change
  *
  * */
  handleDeviceChange(value) {
    console.log(`handleDeviceChange, selected ${value}`);
    this.setState({
        selectedDevice: value,
        selectedConfigurationId: null,
        configurationList: [],
        historyValues: null
      }, () => {
        myService.getConfigurationOfDevice(value, (configs, status) => {
          console.log(`Response callbackConfiguration for Device: ${value}, Length: ${configs.length}`);
      
          if (configs != null) {
            this.setState({configurationList: configs});

            if (this.state.selectedConfigurationId == null) {
              const confId = configs[0].timestamp;
              this.setState({
                firstConfigurationId: confId,
                selectedConfigurationId: confId}
              );
            }
          }
        });
      }
    );
  }


  /* *
  * read history values from device by start and end timestamp
  *
  * */
  readHistory(device) {
    console.log(`read history of device ${device}`);
    this.setState({working: true});
    myService.getHistoryValues(device, this.state.startTime, this.state.endTime, (responseData, status) => {
      console.log("callback history, Status: "+status);
      this.setState({working: false});
      
      if (status === 200) {
        if (responseData != null) console.log("history data length: "+responseData.length);
        if (responseData.length > 0) {
          this.setState({
            historyValues: responseData});
        }
      } else {
        this.openNotification("Fehler beim laden der Werte", `Bitte wählen Sie einen kleineren Zeitraum! (Maximaler Zeitraum: ${tools.sec2Minutes(historyLimit)} Stunden)`);
      }
    });
  }

  

  handleConfigurationChange(value) {
    console.log(`Selected Configuration: ` + value);
    this.setState({
      selectedConfigurationId: value,
      startTime: value
    }, () => {
      const configs = this.getConfigurationsBetweenTimestamps(this.state.startTime+1, this.state.endTime);
      console.log(JSON.stringify(configs, null, 2));
      if (configs.length > 0) {
        this.setState({endTime: configs.reverse()[0]}, () => {
          if (this.state.modal2Visible === false) {
            this.readHistory(this.state.selectedDevice);
          }
        });
      } 
    });
  }


  getConfigurationByTimestamp(timestamp) {
    var arrCfg = this.state.configurationList.filter(a => a.timestamp === timestamp);
    if (arrCfg.length > 0) {
      return arrCfg[0];
    }
    return [];
  }


  configurationHeader() {
    return JSON.stringify((this.getConfigurationByTimestamp(this.state.selectedConfigurationId)), null, 2);
  }
  

  getValuesFromHistory(key, factor, offset) {
    if (this.state.historyValues != null) {
      return this.state.historyValues.map(obj => ((obj[key]) / factor) - offset);
    }
  }


  getConfigurationsBetweenTimestamps(t1, t2) {
    let filter = this.state.configurationList.filter(a => (a.timestamp >= t1 && a.timestamp <= t2));
    let out = filter.map(obj => obj.timestamp);
    //console.log(`getConfigurationsBetween T1 [${t1}] - T2 [${t2}], Array: [${out}]`);
    return out;
  }


  chartData() {
    if (this.state.historyValues != null) {
      //console.log("Prepare Chartdata");
      let out = {
        labels: this.state.historyValues.map(obj => obj["timestamp"]),
        datasets: []
      }
      
      for (const [key, value] of Object.entries(this.getConfigurationByTimestamp(this.state.selectedConfigurationId))) {
        
        if (value !== "null" && key.startsWith("x")) {
          const channelConfig = JSON.parse(value);
          //console.log(channelConfig);
          out.datasets.push({
            label: key+" - "+channelConfig.Name+" ("+channelConfig.Unit+")",
            backgroundColor: tools.colors[channelConfig.Name],
            borderColor: tools.colors[channelConfig.Name],
            borderWidth: 1,
            data: this.getValuesFromHistory(key, channelConfig.Factor, channelConfig.Offset)
          });
        }
      }
      
      return out;
    } else {
      return {
        labels: [" "],
        datasets: [
            {
                label: " ",
                fillColor: "rgba(220,220,220,0.5)",
                strokeColor: "rgba(220,220,220,0.8)",
                highlightFill: "rgba(220,220,220,0.75)",
                highlightStroke: "rgba(220,220,220,1)",
                data: [0]
            }
        ]
      }
    }
  }




  csvHeaders() {
    let headers = [{
      title: "Zeit", 
      label: "Zeit", 
      key: "timestamp", 
      dataIndex: "timestamp"
    }];

    for (const [k, v] of Object.entries(this.getConfigurationByTimestamp(this.state.selectedConfigurationId))) { 
      if (v !== "null" && k.startsWith("x")) {
        const channelConfig = JSON.parse(v);
        headers.push({
          title: `${k} - ${channelConfig.Name} (${channelConfig.Unit})`, 
          label: `${k} - ${channelConfig.Name} (${channelConfig.Unit})`,  
          key: k, 
          dataIndex:k}
        );
      }
    }
    return headers;
  }


  csvData() {
    let data = [];
    //return data;
    if (this.state.historyValues != null) {
      
      const selectedConfiguration = this.getConfigurationByTimestamp(this.state.selectedConfigurationId);
      //console.log('selectedConfiguration: '+JSON.stringify(selectedConfiguration)+ 'Config Length: '+Object.keys(selectedConfiguration).length);

      if (Object.keys(selectedConfiguration).length > 0) {
        data = JSON.parse(JSON.stringify(this.state.historyValues));
        data.reverse();
        data.forEach(function(a) {
          a.timestamp = tools.timestamp2FullDate(a.timestamp, true)

          for (const [k] of Object.entries(a)) {
            if (k.startsWith("x") && selectedConfiguration[k] !== "null") {
              const channelCfg = JSON.parse(selectedConfiguration[k]);
              //console.log('CSV Data Key: '+k+ 'channel Cfg: '+ channelCfg);
              a[k] = ((a[k]) / channelCfg.Factor) - channelCfg.Offset;
            }
          }
        });
      }      
    } 
    return data;
  }


  csvFileName() {
    if (this.state.historyValues != null && this.state.historyValues.length > 0) {
      const first = this.state.historyValues[0];
      const last = this.state.historyValues[this.state.historyValues.length-1];
      return `${this.state.selectedDevice}-${tools.timestamp2FullDate(first.timestamp, false)}-${tools.timestamp2FullDate(last.timestamp, false)}.csv`;
    } else {
      return "export.csv"
    }
  }



  checkConfigsBetween() {
    const configs = this.getConfigurationsBetweenTimestamps(this.state.startTime, this.state.endTime);
    if (configs.length <= 1) {
      console.log('One config exists: '+this.state.firstConfigurationId);
      this.setState({selectedConfigurationId: this.state.firstConfigurationId});
      this.readHistory(this.state.selectedDevice);
    } else {
      console.log('Open modal');
      this.setState({modal2Visible: true});
    }
  }

  
  changeTimeslot(dates, dateStrings) {
    if (dates) {
      let startDate = dates[0].unix();
      let endDate = dates[1].unix();
      console.log(`changeTimeslot from: ${startDate}, to: ${endDate}`);
      if ((endDate - startDate) > historyLimit) {
        console.log("History range to high");
        this.openNotification("Zeitraum zu groß", `Bitte wählen Sie einen kleineren Zeitraum! (Maximaler Zeitraum: ${tools.sec2Minutes(historyLimit)} Stunden)`);
      }  else {
        this.setState({
          startTime: (startDate*1000),
          endTime: (endDate*1000)
        }, () => {
          this.checkConfigsBetween();
        });
      }     
    }
  }

  onOpenCloseChangeTimepicker(open) {
    console.log(`open: ${open}`);
    if (!open) {
      /*
      if ((this.state.endTime - this.state.startTime) > historyLimit) {
        console.log("History range to high");
        this.openNotification("Zeitraum zu groß", `Bitte wählen Sie einen kleineren Zeitraum! (Maximaler Zeitraum: ${tools.sec2Minutes(historyLimit)} Stunden)`);
      }  else {
        this.checkConfigsBetween();
      } 
      */  
    }
  }


  setModal2Visible(val) {
    this.setState({ modal2Visible: val }, () => {
      if (!val) {
        this.readHistory(this.state.selectedDevice);
      }  
    });
  }


  onChangeMode = e => {
    const mode = e.target.value;
    let currTime = new Date().valueOf();
    

    this.setState({
      mode: mode,
      historyValues: null,
      startTime: (mode === "timeslot" ? currTime - (1800*1000) : 0),
      endTime: (mode === "timeslot" ? currTime : 0)
    }, () => {
      if (mode === "timeslot") {
        this.setState({ hold: false });
        this.readHistory(this.state.selectedDevice);
      }
    })
  }


  chartTitle() {
    return (this.state.mode === "timeslot" ? `Zeitraum` : "Live");
  }


  chartSubTitle() {
    if (this.state.historyValues != null && this.state.historyValues.length > 0) {
      const first = this.state.historyValues[0];
      const last = this.state.historyValues[this.state.historyValues.length-1];
      return `${tools.timestamp2FullDate(first.timestamp, false)} - ${tools.timestamp2FullDate(last.timestamp, false)}, Datenpunkte: ${this.state.historyValues.length}`;
    }
    return "";
  }

  

  checkCredentials = (values) => {
    if (values && values.username.length > 0 && values.password.length > 0) {
      const b64str = Buffer.from(values.username+':'+values.password).toString('base64');
      if (b64str === 'YXF1YXRoZXJtOnhlZTdPb2doMmlldmViNGU=') {
        this.setState({ showLoginModal: false });
      }
    }
  };



  holdLive(e) {
    this.setState({ hold: !this.state.hold }, () => {
      if (this.state.hold) {
        this.setState({ holdTime: new Date().valueOf()}, () => {
          console.log(`\nHold: ${this.state.hold}, HoldTime: ${this.state.holdTime}`);
        });
      } else {
        console.log(`\nHold: ${this.state.hold}`);
      }
    });
  }

  resetZoom(e) {
    console.log(this.chartReference);
    this.setState({showChartResetButton: false});
    this.chartReference.current.resetZoom();
  }

  chartDidZoom() {
    console.log("chartDidZoom");
    if (!this.state.showChartResetButton) {
      this.setState({showChartResetButton: true});
    }
  }

  

  render () {
    return (
      <div className="App">
      <div className="CI-Border"></div>  

      {
      this.state.showLoginModal ? 
    
      <Modal
        title="Anmeldedaten eingeben"
        centered
        visible={this.state.showLoginModal}
        closable={false}
        footer={null}>
        <LoginMsg />
        <Form name="basic" labelCol={{span: 6}} wrapperCol={{span: 16}} onFinish={this.checkCredentials}  autoComplete="off" initialValues={{ username: config.username, password: config.password }}>
          <Form.Item label="Benutzername" name="username" rules={[
              {
                required: true,
                message: 'Bitte Benutzername eingeben!',
              },
            ]}
          >
          <Input/>
          </Form.Item>

          <Form.Item label="Passwort" name="password" rules={[
              {
                required: true,
                message: 'Bitte Passwort eingeben!',
              },
            ]}
          >
          <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{offset: 6, span: 16}}>
            <Button type="primary" htmlType="submit">
              OK
            </Button>
          </Form.Item>
        </Form>
        
      </Modal>
      
      : 
      
      <header className="App-header">
        
        <div id="grid">
          <div>
            <img src={logo} className="App-logo" alt="logo"/>
          </div>
          <div>
            <table>
            <tbody>
            <tr>
              <td className="Default">
                Gerät: &nbsp;
                <Select size="middle" value={this.state.selectedDevice} style={{ width: 180 , padding:4}} onChange={this.handleDeviceChange}>
                  {this.state.devices.map(obj => (<Option value={obj}>{obj.replace("client-cert_", "")}</Option>))}
                </Select>
              </td>
              <td>&nbsp;&nbsp;</td>
              <td className="Default">
                Modus: &nbsp;
                <Radio.Group
                  options={modeOptions}
                  onChange={this.onChangeMode}
                  value={this.state.mode}
                  optionType="button"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                { this.state.mode === "timeslot" ?
                  <ConfigProvider locale={deDE}>
                    <RangePicker size="middle" style={{ width: 350 , padding:4}} 
                      showTime
                      defaultValue={[moment(this.state.startTime), moment(this.state.endTime)]}
                      format="DD.MM.YYYY HH:mm:ss"
                      onChange={this.changeTimeslot}
                      onOpenChange={this.onOpenCloseChangeTimepicker}
                    />
                  </ConfigProvider>
                  : null 
                }
              </td>
              <td>&nbsp;&nbsp;</td>
              <td className="Default">  
              { this.state.mode !== "timeslot" ?
                  <Button 
                    className="Select-CFG" 
                    type="primary" 
                    shape="round" 
                    style={{backgroundColor: tools.colors.tint, borderColor:"white", paddingTop:4, paddingBottom:4}}
                    icon={this.state.hold ? <CaretRightOutlined /> : <PauseOutlined />} 
                    onClick={this.holdLive}
                    size="large"/>
                  : null 
              }
                
              </td>
            </tr>
            </tbody>
            </table>
          </div>
          <div> 
            <CSVLink data={this.csvData()} headers={this.csvHeaders()} filename={this.csvFileName()}>
              <Button style={{backgroundColor: tools.colors.tint, borderColor:"white"}} className="Select-CFG" type="primary" shape="round" icon={<DownloadOutlined />} size="large"></Button>
            </CSVLink>
          </div>
        </div>
        <div id="gridSpinner">
          <div><div className='smallBox'/></div>
          <div><Spin size="large" spinning={this.state.working} /></div>
          <div><Button style={{backgroundColor: tools.colors.tint, borderColor:"white"}} size="large" type="primary" shape="round" onClick={this.resetZoom} hidden={!this.state.showChartResetButton}>Reset Zoom</Button></div>
        </div>

        <Line
          data={this.chartData()}
          ref={this.chartReference}
          options={{
            layout: {
              padding: 10
            },
            animation: {duration: 0},
            hover: {animationDuration: 0},
            responsiveAnimationDuration: 0,
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              title: {
                display: this.state.historyValues != null,
                text: this.chartTitle()
              },
              subtitle: {
                display: this.state.historyValues != null,
                text: this.chartSubTitle()
              },
              legend: {
                display: true,
                position: 'bottom',
                labels: { usePointStyle: true }
              },
              zoom: {
                zoom: {
                  wheel: {
                    mode: "xy",
                    enabled: true // SET SCROOL ZOOM TO TRUE
                  },
                  onZoomComplete:this.chartDidZoom
                },
                pan: {
                  enabled: true,
                  mode: "xy",
                  speed: 100
                }
              }
            },
            
            scales: {
              x: {
                type: 'time',
                time: {
                  minUnit: 'second',                                                                                                                                                                  
                  tooltipFormat: 'DD.MM.YYYY HH:mm:ss,SSS',
                  displayFormats: {
                      millisecond: 'HH:mm:ss', // 'HH:mm:ss.SSS',
                      second: 'HH:mm:ss',
                      minute: 'HH:mm',
                      hour: 'HH'
                  }
                },
                ticks: {
                  source: 'auto',
                  maxLiveValues:30
                }
              }
            }
          }}
        />


        

        <Collapse defaultActiveKey={['1']} >
          <Panel header="Sensorwerte als Tabelle" key="2">
            <Table dataSource={this.csvData()} columns={this.csvHeaders()} locale={deDE} pagination={{showSizeChanger: true, pageSizeOptions:[10,25,50,100]}}/>
          </Panel>
        </Collapse>
        
        <Modal
          title="Mehrere Konfigurationen gefunden"
          centered
          visible={this.state.modal2Visible}
          onOk={() => this.setModal2Visible(false)}
          onCancel={() => this.setModal2Visible(false)}
        >
          <p>Bitte wählen Sie die passende Konfiguration zu den Datenpunkten aus.</p>
            Konfiguration &nbsp;
            <Select size="middle" bordered={false} value={this.state.selectedConfigurationId} className="Select-CFG" style={{ width: 200 , color: "white", padding:4}} onChange={this.handleConfigurationChange}>
              {this.getConfigurationsBetweenTimestamps(this.state.startTime, this.state.endTime).map(obj => (<Option value={obj}>{tools.timestamp2Time(obj)}</Option>))}
            </Select>
        </Modal>
      </header>  

    }
    </div>
    )
  }
}

export default App;