import moment from 'moment';
  

class Tools {

    colors = {
        tint: "#57A359",
        Flow: "#2bce03",
        Temperature: "#4f7efc",
        Pressure: "#f74c66",
        vRms: "#E933FF"
    };

    sec2Minutes(seconds) {
        return moment().startOf('day').seconds(seconds).format('HH:mm');
    }

    timestamp2FullDate(timestamp, withMillisec) {
        var date = new Date(timestamp);

        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'};
        let out = date.toLocaleDateString('de-DE', options);
        if (withMillisec) {
            let miliStr = `${date.getMilliseconds()}`.padStart(3, '0');
            out = out + `,${miliStr}`;
        }
        return out;
    };


    timestamp2Time(timestamp) {
        var date = new Date(timestamp);

        const options = {hour: 'numeric', minute: 'numeric', second: 'numeric'};
        const out = date.toLocaleTimeString('de-DE', options);

        return out;
    };

    
    sec2Min(sec) {
        return (sec/60)+" Min";
    }


}


  export default Tools;