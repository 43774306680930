const baseUrl = 'https://api.aquatherm.iot.qbeyond.tech';
const basicAuth = 'Basic YXF1YXRoZXJtOnhlZTdPb2doMmlldmViNGU=';
const pathConfig = '/cloud/configuration';
const pathConfigClient = '/cloud/configuration/';


export default class Service {


    getDevices(callback) {
        this.readURL(pathConfig, callback);
    } 
    
    getConfigurationOfDevice(deviceId, callback) {
        this.readURL(pathConfigClient+deviceId, callback);
    }  

    getHistoryValues(deviceId, from, till, callback) {
        this.readURL(`/cloud/history/${deviceId}?from=${from}&until=${till}`, callback);
    }  

    async readURL(url, callback) {
        const response = await fetch(baseUrl+url, {
            method:'GET',
            mode:'cors',
            headers: {
                'Origin': 'https://aquatherm2.iot.qbeyond.tech',
                'Content-Type': 'application/json;charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': basicAuth
                }
        });
        
        console.log(response.url)
        if (response.ok) {
            callback(await response.json(), response.status);
        } else {
            callback(null, response.status);
        }
    };
}
